// OnboardingContentCardsToggle.js
'use client';
import React, { useEffect, useState } from 'react';
import OnboardingContentCards from '../OnboardingContentCards';
import Row from '@/ui/Row';
import Card from '@/ui/Card';
import * as Icon from '@/ui/Icon';
import Cookies from 'js-cookie';

const OnboardingContentCardsToggle = ({ initialVisibility }: { initialVisibility: Boolean }) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);

  useEffect(() => {
    Cookies.set('onboardingCardState', isVisible.toString(), { expires: 7 });
  }, [isVisible]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const arrowStyle = {
    transform: isVisible ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s',
  };

  return (
    <div style={{ paddingBottom: '30px' }}>
      <Card gap={20}>
        <div onClick={toggleVisibility}>
          <Row style={{ cursor: 'pointer', alignItems: 'center' }} justifyContent="space-between">
            <h6>Here are some things to get started 🐂 🚀</h6>
            <div style={arrowStyle}>
              <Icon.Up />
            </div>
          </Row>
        </div>
        {isVisible && <OnboardingContentCards />}
      </Card>
    </div>
  );
};

export default OnboardingContentCardsToggle;
