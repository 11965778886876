const useReferralCode = () => {
  const isClient = typeof window !== 'undefined';
  const localStorage = isClient ? window.localStorage : null;

  const referralCode = isClient && JSON.parse(localStorage?.getItem('referralCode') as string);

  const setReferralCode = (code: string | string[] | undefined) => {
    if (code) {
      localStorage?.setItem('referralCode', JSON.stringify(code));
    }
  };

  return { referralCode, setReferralCode } as const;
};

export default useReferralCode;
